import styled from 'styled-components'

const BlankHeader = styled.header`
  height: 60px;
  @media screen and (min-width: 768px) {
    height: 69px;
  }
`

export { BlankHeader }
