import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'

import { Logo, Link, OvodLogo } from './common'
import MobileLogo from '../assets/images/mobile-logo.svg'

const Footer = props => {
  const { className, pathname, windowWidth } = props
  return (
    <footer className={className}>
      <Container>
        <Row>
          <Col
            xs={12}
            lg={4}
            className="px-lg-0 mb-5 mb-lg-0 d-flex align-items-center justify-content-lg-start"
          >
            {windowWidth < 768 ? (
              <MobileLogo />
            ) : (
              <Logo
                grayscale
                className="d-inline-flex"
                pathname={pathname}
                modifiers={['footer']}
              />
            )}

            <OvodLogo />
          </Col>
          <Col
            xs={12}
            lg={4}
            className="text-lg-center d-flex justify-content-lg-center align-items-center order-3 order-lg-2"
          >
            <p className="mb-0" style={{ color: '#fff', opacity: 0.2 }}>
              <span className="text-nowrap">
                © {new Date().getFullYear()} Tidykit.net.
              </span>{' '}
              <span className="text-nowrap">All rights reserved.</span>
            </p>
          </Col>
          <Col
            xs={12}
            lg={4}
            className="mb-5 mb-lg-0 d-flex align-items-center justify-content-lg-end order-2 order-lg-3"
          >
            <ul className="list-unstyled d-flex align-items-center mb-0">
              <li className="ml-lg-5">
                <Link to="/terms" modifiers="footer">
                  Terms
                </Link>
              </li>
              <li className="ml-5">
                <Link to="/conditions" modifiers="footer">
                  Conditions
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
  pathname: PropTypes.string,
  windowWidth: PropTypes.number.isRequired,
}

export default styled(Footer)`
  padding-top: 36px;
  padding-bottom: 36px;
  background-color: rgba(0, 0, 0, 0.23);
  p {
    font-size: 1.3rem;
    line-height: 1;
    letter-spacing: 0.4px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
  }
  a {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
  @media screen and (min-width: 991px) {
    background-color: transparent;
    padding-top: 32px;
    padding-bottom: 32px;
  }
`
