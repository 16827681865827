import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import { scroller } from 'react-scroll'
import { navigate } from 'gatsby'

import { BlankHeader, Button } from './common'
import NavBar from './NavBar'
import { LayoutContext } from './Layout'

const CTAWrapper = styled.div`
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  padding-left: 24px;
  padding-right: 24px;
  @media screen and (max-height: 439px) {
    position: static;
    bottom: inherit;
    left: inherit;
    right: inherit;
    padding: 0;
  }
`

const MobileMenu = props => {
  const { className, isMobileMenuOpen, closeMobileMenu, pathname } = props
  const { windowWidth } = useContext(LayoutContext)
  useEffect(() => {
    if (isMobileMenuOpen && windowWidth > 991) {
      closeMobileMenu()
    }
    return () => closeMobileMenu()
  }, [windowWidth])
  return (
    <div className={cx('d-flex flex-column overflow-auto', className)}>
      <BlankHeader />
      <Container style={{ position: 'relative', height: 'calc(100vh - 69px)' }}>
        <Row>
          <Col
            xs={12}
            className="d-flex flex-column p-4"
            style={{ position: 'static' }}
          >
            <NavBar
              beforeScrollHandle={() => {
                if (!isMobileMenuOpen) return null
                closeMobileMenu()
              }}
              pathname={pathname}
            />

            <CTAWrapper>
              <Button
                className="w-100"
                modifiers="empty"
                onClick={() => {
                  if (isMobileMenuOpen) closeMobileMenu()
                  if (pathname === '/') {
                    return scroller.scrollTo('get-early-access', {
                      smooth: true,
                    })
                  }
                  return navigate('/#get-early-access')
                }}
              >
                Get Early Access
              </Button>
            </CTAWrapper>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

MobileMenu.propTypes = {
  className: PropTypes.string,
  isMobileMenuOpen: PropTypes.bool.isRequired,
  closeMobileMenu: PropTypes.func.isRequired,
  pathname: PropTypes.string,
}

MobileMenu.defaultProps = {
  pathname: '/',
}

export default styled(MobileMenu)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  background-color: #000;

  li {
    padding: 20px 0 !important;
    a {
      font-size: 2.6rem;
      font-weight: bold;
      line-height: 1.04;
      letter-spacing: 0.24px;
    }
  }
`
