import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import {
  applyStyleModifiers,
  styleModifierPropTypes,
} from 'styled-components-modifiers'
import cx from 'classnames'

import { scroller } from 'react-scroll'
import { Logo, Link, BurgerButton, Button } from './common'
import NavBar from './NavBar'
import Arrow from '../assets/images/icons/arrow.svg'

const MODIFIER_CONFIG = {
  blur: () => `
    background-color: #1c1d1f;
    @media screen and (min-width: 768px) {
      transition: all 0.4s ease-in-out;
      backdrop-filter: blur(20px);
      background-color: rgba(0, 0, 0, 0.1);
    }
  `,
}

const ArrowLink = styled(Link)`
  @media screen and (min-width: 768px) {
    &:hover {
      .Icon-Arrow {
        margin-left: 12px;
        margin-right: -4px;
        transition: margin 0.4s ease-in-out;
      }
    }
    .Icon-Arrow {
      margin-left: 8px;
      transition: margin 0.4s ease-in-out;
      width: 12px;
      height: 10px;
    }
  }
`

const Header = props => {
  const {
    windowWidth,
    className,
    isMobileMenuOpen,
    toggleMobileMenu,
    pathname,
    closeMobileMenu,
    modifiers,
  } = props
  return (
    <header className={cx(className, isMobileMenuOpen && 'Header-Blacked')}>
      <Container>
        <Row>
          <Col
            xs={6}
            lg={2}
            className="pr-md-0 d-flex flex-column justify-content-center"
          >
            <Logo
              grayscale={isMobileMenuOpen}
              signOnly={isMobileMenuOpen}
              pathname={pathname}
              isMobileMenuOpen={isMobileMenuOpen}
              beforeClick={closeMobileMenu}
              modifiers={['footer']}
            />
          </Col>
          {/* eslint-disable-next-line no-nested-ternary */}
          {windowWidth ? (
            windowWidth < 992 ? (
              <>
                <Col
                  xs={6}
                  className="d-flex justify-content-end align-items-center"
                >
                  <BurgerButton
                    type="button"
                    style={{ zIndex: 10 }}
                    isOpen={isMobileMenuOpen}
                    onClick={() => toggleMobileMenu()}
                  />
                </Col>
              </>
            ) : (
              <>
                <Col lg={8} className="px-3 d-flex align-items-center">
                  <NavBar className="d-flex px-3" pathname={pathname} />
                </Col>
                <Col
                  lg={2}
                  className="d-flex align-items-center pl-md-0 justify-content-end"
                >
                  {!modifiers || modifiers?.indexOf('blur') < 0 ? (
                    <ArrowLink
                      to="/#get-early-access"
                      modifiers="alt"
                      onClick={e => {
                        if (pathname !== '/') return null
                        e.preventDefault()
                        scroller.scrollTo('get-early-access', { smooth: true })
                      }}
                    >
                      Get Early Access
                      <Arrow className="Icon-Arrow" />
                    </ArrowLink>
                  ) : (
                    <Button
                      className="mx-0 px-0"
                      modifiers={['blurredHeader']}
                      onClick={e => {
                        if (pathname !== '/') return null
                        e.preventDefault()
                        scroller.scrollTo('get-early-access', { smooth: true })
                      }}
                    >
                      Get Early Access
                    </Button>
                  )}
                </Col>
              </>
            )
          ) : null}
        </Row>
      </Container>
      <div className="Header-Backdrop" />
    </header>
  )
}

Header.propTypes = {
  className: PropTypes.string.isRequired,
  windowWidth: PropTypes.number.isRequired,
  isMobileMenuOpen: PropTypes.bool.isRequired,
  toggleMobileMenu: PropTypes.func.isRequired,
  closeMobileMenu: PropTypes.func.isRequired,
  pathname: PropTypes.string,
  // Setup validation of the "normal" modifier flags:
  // eslint-disable-next-line react/no-unused-prop-types
  modifiers: styleModifierPropTypes(MODIFIER_CONFIG),
}

Header.defaultProps = {
  pathname: '/',
}

export default styled(Header)`
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  left: 0;
  padding: 16px 0;
  @media screen and (min-width: 768px) {
    transition: all 0.4s ease-in-out;
    padding: 15px 0;
    a {
      font-size: 1.4rem !important;
      line-height: 2.5rem !important;
    }
  }
  @media screen and (min-width: 1200px) {
    a {
      font-size: 1.7rem !important;
    }
  }
  &.Header-Blacked {
    background-color: #000 !important;
  }
  // apply the modifier configuration.
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`
