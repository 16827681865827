import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  applyStyleModifiers,
  styleModifierPropTypes,
} from 'styled-components-modifiers'

const MODIFIER_CONFIG = {
  empty: ({ theme }) => `
    border-color: ${theme.colors.primary};
    background-color: transparent;
    color: ${theme.colors.primary};
    &:hover {
      color: #fff !important;
    }
    &:active,
    &:focus {
      background-color: ${theme.colors.primary};
      color: #fff !important;
    }
  `,
  fixedSize: () => `
    @media screen and (min-width: 768px) {
      width: 218px !important;
      height: 55px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      align-text: center;
    }
  `,
  blurredHeader: () => `
    width: 150px !important;
    height: 38px !important;
    padding: 8px 0 6px;
    font-size: 15px;
    line-height: 1;
    text-align: center;
  `,
}

const Button = props => {
  const { children, className, type } = props
  return (
    // eslint-disable-next-line react/button-has-type
    <button {...props} type={type || 'button'} className={className}>
      {children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  // Setup validation of the "normal" modifier flags:
  modifiers: styleModifierPropTypes(MODIFIER_CONFIG),
}

export default styled(Button)`
  padding: 13px 31px;
  border-radius: 56px;
  background-color: ${({ theme }) => theme.colors.primary};
  border: 1px ${({ theme }) => theme.colors.primary} solid;
  color: #fff;
  margin: 0;
  outline: none !important;
  transition: all 0.4s ease-in-out;
  width: 100%;
  font-weight: 500;

  @media screen and (min-width: 768px) {
    width: auto;
    margin-left: 12px;
    margin-right: 12px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryHover};
    border-color: ${({ theme }) => theme.colors.primaryHover};
  }
  &:active,
  &:focus {
    background-color: ${({ theme }) => theme.colors.primaryActive};
    border-color: ${({ theme }) => theme.colors.primaryActive};
  }
  &:disabled {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.greyActive};
    display: none;

    @media screen and (min-width: 768px) {
      display: inline;
    }
  }

  ${applyStyleModifiers(MODIFIER_CONFIG)};
`
