/* eslint-disable react/no-unused-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import omit from 'lodash.omit'

const SEO_QUERY = graphql`
  query SEO_QUERY {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
    allSeoYaml {
      edges {
        node {
          title
          pathname
          description
          id
        }
      }
    }
    appLogo: file(name: { eq: "app-icon" }, ext: { eq: ".png" }) {
      publicURL
    }
  }
`

const SEO = props => {
  const {
    site: { siteMetadata: defaults },
    allSeoYaml: { edges: dataSeo },
    appLogo,
  } = useStaticQuery(SEO_QUERY)
  const { pathname, meta } = props
  const seoFromDocs = dataSeo.find(({ node }) => node.pathname === pathname)
    ?.node
  // get all data
  const seo = {
    ...defaults,
    ...(seoFromDocs && omit(seoFromDocs, 'pathname')),
    ...omit(props, ['meta', 'pathname']),
    title: seoFromDocs?.title || defaults?.title,
    description: seoFromDocs?.description || defaults?.description,
  }
  const { lang, title, description, author } = seo
  const siteUrl =
    process.env.NODE_ENV !== 'development'
      ? seo.siteUrl
      : 'https://localhost:8000'
  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={`%s | ${defaults?.title}`}
      key={seo.id}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: siteUrl + appLogo.publicURL,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          property: `twitter:image`,
          content: siteUrl + appLogo.publicURL,
        },
      ].concat(meta)}
    />
  )
}

SEO.propTypes = {
  pathname: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
}

export default SEO
