import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeContext } from 'styled-components'
import CreatableSelect from 'react-select/creatable'
import { FormGroup } from 'react-bootstrap'
import omit from 'lodash.omit'

const Select = props => {
  const { className, id, name, placeholder } = props
  const theme = useContext(ThemeContext)

  const reactSelectStyles = {
    valueContainer: styles => ({
      ...styles,
      paddingLeft: 24,
      height: 55,
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: `${
        // eslint-disable-next-line no-nested-ternary
        state.isFocused
          ? theme.colors.primary
          : state.hasValue
          ? theme.colors.greyActive
          : 'rgba(255, 255, 255, 0.18)'
      } !important`,
      borderRadius: 64,
      backgroundColor: 'transparent',
      boxShadow: 'none !important',
      '&:hover': {
        borderColor: `${
          !state.isDisabled ? theme.colors.primary : 'rgba(255, 255, 255, 0.18)'
        } !important`,
      },
    }),
    singleValue: styles => ({
      ...styles,
      color: '#fff',
    }),
    indicatorsContainer: styles => ({
      ...styles,
      paddingLeft: 13,
      paddingRight: 13,
    }),
    container: styles => ({
      ...styles,
      outline: 'none !important',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: `${theme.colors.gray} !important`,
      transition: 'all 0.4s ease-in-out',
      transform: state.isFocused ? 'rotate3d(1,0,0,180deg)' : null,
      '&:hover': {
        color: `${theme.colors.grayHover} !important`,
      },
    }),
    indicatorSeparator: styles => ({
      ...styles,
      display: 'none',
    }),
    placeholder: styles => ({
      ...styles,
      color: `${theme.secondary} !important`,
      zIndex: 3,
      margin: 0,
      whiteSpace: 'nowrap',
    }),
    menu: styles => ({
      ...styles,
      margin: 0,
      padding: 0,
      borderRadius: 24,
      overflow: 'hidden',
      border: 'solid 1px #414645',
      backgroundColor: '#141517',
      boxShadow: '0 8px 47px 0 rgba(0, 0, 0, 0.5)',
      zIndex: 19,
    }),
    menuList: styles => ({
      ...styles,
      margin: 0,
      padding: 0,
    }),
    noOptionsMessage: styles => ({
      ...styles,
      padding: '16px 24px 18px',
      textAlign: 'left',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? theme.colors.pink : 'transparent',
      padding: '16px 24px 18px',
      textAlign: 'left',
      '&:hover': {
        backgroundColor: state.isSelected
          ? theme.colors.pink
          : 'rgba(216, 72, 157, 0.2)',
      },
    }),
    input: styles => ({
      ...styles,
      caretColor: theme.colors.primary,
      color: '#fff',
    }),
  }

  return (
    <FormGroup className={className}>
      <label htmlFor={id || name} className="d-none">
        {placeholder}
      </label>
      <CreatableSelect
        {...omit(props, ['className'])}
        styles={reactSelectStyles}
        placeholder={placeholder}
      />
    </FormGroup>
  )
}

Select.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export default styled(Select)`
  width: 100%;
  margin-bottom: 24px;
  @media screen and (min-width: 768px) {
    margin-bottom: 40px;
  }
`
