/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const React = require('react')

const Layout = require('./src/components/Layout').default

exports.onClientEntry = () => {
  // if user is in browser
  if (typeof window === 'undefined' || typeof document === 'undefined') {
    return null
  }
  // Unhandled rejection handler
  window.addEventListener('unhandledrejection', event => {
    event.preventDefault()
    console.error(
      `UNHANDLED PROMISE REJECTION: ${event.reason ||
        event.message ||
        JSON.stringify(event, null, '\t')}`
    )
  })
}

// eslint-disable-next-line react/prop-types,react/display-name
exports.wrapPageElement = ({ element, props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Layout {...props}>{element}</Layout>
)
