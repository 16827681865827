import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import omit from 'lodash.omit'
import { Link as Anchor } from 'gatsby'
import {
  applyStyleModifiers,
  styleModifierPropTypes,
} from 'styled-components-modifiers'

const MODIFIER_CONFIG = {
  alt: ({ theme }) => `
    color: ${theme.colors.pink};
    &:hover {
      color: #fff;
    }
  `,
  footer: ({ theme }) => `
    color: ${theme.colors.grey};
    letter-spacing: 0.4px;
    &:hover {
      color: ${theme.colors.greyHover};
    }
    &:active,
    &:focus {
      color: ${theme.colors.greyActive};
    }
  `,
}

const A = styled(Anchor)`
  font-size: 1.8rem;
  line-height: 2.7rem;
  letter-spacing: 0.05px;
  color: #fff;
  transition: all 0.4s ease-in-out;

  & > svg {
    path {
      fill: ${({ theme }) => theme.colors.pink};
      transition: all 0.4s ease-in-out;
    }
  }

  &:hover {
    color: ${({ theme }) => theme.colors.pink};
    text-decoration: none;
  }
  &:active,
  &:focus {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
    & > svg {
      path {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  ${applyStyleModifiers(MODIFIER_CONFIG)};
`

const Link = props => {
  const { children, to } = props
  const internal = /^\/(?!\/)/.test(to)

  if (internal) {
    return <A {...props}>{children}</A>
  }

  return (
    <A as="a" {...omit(props, ['to'])} href={to}>
      {children}
    </A>
  )
}

Link.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  to: PropTypes.string.isRequired,
  // Setup validation of the "normal" modifier flags:
  modifiers: styleModifierPropTypes(MODIFIER_CONFIG),
}

export default Link

export { A as Anchor }
