import React from 'react'
import parse from 'html-react-parser'

import { Link } from '../components/common'

const html2jsx = string =>
  parse(string, {
    replace(domNode) {
      if (domNode?.attribs?.href && domNode.children?.[0]?.data) {
        return React.createElement(
          Link,
          {
            ...domNode.attribs,
            to: domNode.attribs.href,
            className: domNode.attribs.class,
          },
          domNode.children[0].data
        )
      }
    },
  })

export default html2jsx
