import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import cx from 'classnames'

import Dots from '../../assets/images/dots.svg'

const Squares = styled(Dots)`
  display: none;
  @media screen and (min-width: 768px) {
    position: absolute;
    left: 50%;
    top: 29px;
    transform: translateX(-50%);
    width: 368px;
    display: inherit;
  }
`

const Tripod = styled.span`
  @media screen and (min-width: 768px) {
    height: 39px;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.1);
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    left: 0;
    margin-top: 1px;

    &:after {
      content: '';
      display: block;
      width: 18px;
      height: 1px;
      left: 1px;
      background-color: rgba(255, 255, 255, 0.1);
      top: calc(50% - 1px);
      position: absolute;
    }

    &.Title-Tripod_ToTheLeft {
      left: initial;
      right: 0;

      &:after {
        left: initial;
        right: 1px;
      }
    }
  }
`

const Title = props => {
  const { className, children } = props
  return (
    <div className={cx('w-100', className)}>
      <Tripod />
      <h2>{children}</h2>
      <Squares />
      <Tripod className="Title-Tripod_ToTheLeft" />
    </div>
  )
}

Title.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

export default styled(Title)`
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-bottom: 29px;

  @media screen and (min-width: 768px) {
    display: block;
    align-items: inherit;
    overflow: inherit;
    margin-bottom: 6px;
  }

  h2 {
    margin: 0 !important;
  }
`
