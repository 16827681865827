import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import cx from 'classnames'
import { animateScroll as scroll } from 'react-scroll'
import {
  applyStyleModifiers,
  styleModifierPropTypes,
} from 'styled-components-modifiers'

import L from '../../assets/images/label.svg'
import S from '../../assets/images/sign.svg'

const MODIFIER_CONFIG = {
  footer: () => `
    width: 124px;
    height: 34px;
    @media screen and (min-width: 768px) {
      width: 142px;
      height: 39px;
    }
  `,
}

const Sign = styled(S)`
  width: 25px;
  height: 28px;
  &,
  & path {
    transition: all 0.4s ease-in-out;
  }

  &.Logo-Sign_grayscale {
    path {
      fill: #5d5d5f;
    }
  }

  @media screen and (min-width: 768px) {
    width: 40px;
    height: 45px;
  }
`
const Label = styled(L)`
  margin-left: 7px;
  width: 70px;
  height: 23px;

  &,
  & path {
    transition: all 0.4s ease-in-out;
  }

  &.Logo-Label_grayscale {
    path {
      fill: #5d5d5f;
    }
  }

  @media screen and (min-width: 768px) {
    margin-left: 12px;
    width: 112px;
    height: 37px;
  }
`

const Logo = props => {
  const {
    className,
    signOnly,
    grayscale,
    pathname,
    isMobileMenuOpen,
    beforeClick,
    style,
    // modifiers,
  } = props
  return (
    <Link
      to="/"
      aria-label="Go to Homepage"
      className={className}
      style={style}
      onClick={e => {
        if (isMobileMenuOpen) beforeClick()
        if (pathname !== '/') return null
        e.preventDefault()
        scroll.scrollToTop({ smooth: true })
      }}
    >
      <Sign className={cx(grayscale && 'Logo-Sign_grayscale')} />
      <Label
        className={cx(
          signOnly && 'invisible',
          grayscale && 'Logo-Label_grayscale'
        )}
      />
    </Link>
  )
}

Logo.propTypes = {
  className: PropTypes.string,
  signOnly: PropTypes.bool,
  grayscale: PropTypes.bool,
  pathname: PropTypes.string,
  isMobileMenuOpen: PropTypes.bool,
  beforeClick: PropTypes.func,
  style: PropTypes.object,
  // Setup validation of the "normal" modifier flags:
  // eslint-disable-next-line react/no-unused-prop-types
  modifiers: styleModifierPropTypes(MODIFIER_CONFIG),
}

Logo.defaultProps = {
  signOnly: false,
  grayscale: false,
  pathname: '/',
  isMobileMenuOpen: false,
  beforeClick: () => null,
}

export default styled(Logo)`
  display: flex;
  align-items: center;
  max-width: 100%;
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`
