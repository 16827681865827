import { createGlobalStyle } from 'styled-components'
import '../vendor/bootstrap.css'

const globalTheme = createGlobalStyle`
  * {
    box-sizing: border-box;
    //transition: all 0.4s ease;
  }

  html, body {
    height: 100%;
  }
  
  html {
    font-size: 62.5%;
  }
  
  body {
    background-color: ${({ theme }) => theme.colors.background};
    height: 100vh; /* Avoid the IE 10-11 \`min-height\` bug. */
    font-size: 1.6rem;
    font-family: ${({ theme }) => theme.fonts.primary};
    color: #fff;
    font-smooth: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  h1,
  h2,
  h3 {
    &,
    & > span,
    & > a {
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.24px;
      margin-top: 14px;
      margin-bottom: 14px;
      @media screen and (min-width: 768px) {
        margin-top: 12px;
        margin-bottom: 12px;
      }
    }
  }

  h1 {
    &,
    & > span,
    & > a {
      font-size: 34px !important;
      line-height: 1.41;
      @media screen and (min-width: 768px) {
        font-size: 5.2rem !important;
        line-height: 1.23;
      }
    }
  }
  
  h2 {
    &,
    & > span,
    & > a {
      font-size: 5.2rem !important;
      line-height: 1.08;
      @media screen and (min-width: 768px) {
        font-size: 5rem !important;
        line-height: 1.44;
      }
    }
  }
  
  h3 {
    &,
    & > span,
    & > a {
      font-size: 2.3rem;
      font-weight: 500;
      line-height: normal;
      letter-spacing: normal;
      @media screen and (min-width: 768px) {
        font-size: 2.6rem;
      }
    }
  }
  
  p {
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: 0.6px;
  }
  
  .invisible {
    opacity: 0;
  }

  .Text-Primary {
    color: ${({ theme }) => theme.colors.primary};
  }
  .Text-Pink {
    color: ${({ theme }) => theme.colors.pink};
  }
  .Text-Violet {
    color: ${({ theme }) => theme.colors.violet};
  }
  .Text-Blue {
    color: ${({ theme }) => theme.colors.blue};
  }
  .Text-Aqua {
    color: ${({ theme }) => theme.colors.aqua};
  }
  .Text-Grey {
    color: ${({ theme }) => theme.colors.grey};
  }
  .Text-White {
    color: #fff;
  }
  
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid ${({ theme }) => theme.colors.grey};
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }
  
  // modal styles
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.91);
    align-items: center;
    justify-content: center;
    z-index: 21;
  }
  .modal-body {
    padding: 0 15px;
    position: relative;
    overflow-y: auto;
    z-index: 20;
    height: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media screen and (min-width: 577px) {
      padding: 30px 0 !important;
    }
  }
  .ReactModal__Body--open {
    overflow: hidden;
  }
`

export default globalTheme
