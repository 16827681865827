import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import { LayoutContext } from '../Layout'
import { ModalBody } from './ThankYou'
import { Button } from '../common'
import Arrow from '../../assets/images/icons/arrow.svg'
import { html2jsx } from '../../utils'

const ERROR_MODAL_QUERY = graphql`
  query ERROR_MODAL_QUERY {
    illustration: file(name: { eq: "illustration" }, ext: { eq: ".svg" }) {
      id
      publicURL
    }
  }
`

const Illustration = styled.img`
  width: 100%;
  display: block;
  margin-bottom: 26px;

  @media screen and (min-width: 577px) {
    width: 404px;
    height: 274px;
    margin-bottom: 25px;
  }
`
const ArrowBack = styled(Arrow)`
  width: 12px;
  height: 10px;
  transform: rotate(180deg);
  path {
    fill: #fff;
  }
`

const Error = props => {
  const { closeModal } = useContext(LayoutContext)
  const { className, msg } = props
  const { illustration } = useStaticQuery(ERROR_MODAL_QUERY)
  return (
    <ModalBody className={className}>
      <Illustration
        key={illustration?.id}
        src={illustration?.publicURL}
        alt="Error!"
      />
      <h1>Oh no, something went wrong!</h1>
      <p>{html2jsx(msg)}</p>
      <Button onClick={() => closeModal()} modifiers={['fixedSize']}>
        <ArrowBack /> Go Back
      </Button>
    </ModalBody>
  )
}

Error.propTypes = {
  className: PropTypes.string,
  msg: PropTypes.string,
}

Error.defaultProps = {
  msg:
    'So sorry, but our site is under maintenance right now. We’re doing our best and we’ll back' +
    ' soon. Suggest you to go back.',
}

export default styled(Error)`
  h1 {
    font-size: 28px !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: 0.24px;
  }
  p {
    margin-bottom: 23px;
  }

  @media screen and (min-width: 768px) {
    h1 {
      font-size: 32px !important;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.72;
      letter-spacing: 0.24px;
      text-align: center;
    }
    p {
      margin-top: 10px;
      margin-bottom: 33px;
    }
  }
`
