import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Logo, Button } from '../common'
import defaultTheme from '../../assets/styles/themes'
import { LayoutContext } from '../Layout'

export const HelpDetails = () => {
  const { closeModal } = React.useContext(LayoutContext)

  return (
    <WrapperS>
      <Logo />
      <InnerWrapperS>
        <h2>Hi guys ✋</h2>
        <h3>We are the Tidykit team.</h3>
        <p>
          And we want to <b>Thank you 💕</b> for a most pleasent words and
          interest to our awesome product. And our hope to make your every day
          work with Cinema 4D more adorable, simple and effective.{' '}
        </p>
        <p>
          We work really hard this months and we got a real awesome stuff for
          you so far. And looking forward to share it with you as soon as
          possible!!! But because of the COVID-19 epidemic, we have been left
          without the funds and any possibility to make the tidykit product to
          be ready to use.
        </p>
        <p>
          <b>🙏PLEASE HELP</b> US to finalize the Tidykit plugin to alive and
          share with you. Not enough funds will 🗡️kill our project for an
          indefinately period.
        </p>
        <p>
          Any amount your donate will save us from this crysis. And all of you
          by helping Tidykit will have the full
          <b>premium</b>
          accounts <b>Forever for Free</b>.
        </p>
        <h3>Together We Are Strong!</h3>
        <Button onClick={closeModal}>Help Tidykit</Button>
      </InnerWrapperS>
      <FooterS>
        🤞 Sent with hope from{' '}
        <Link to="/help" onClick={closeModal}>
          Tidykit
        </Link>{' '}
        team
      </FooterS>
    </WrapperS>
  )
}
const WrapperS = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 99;
  top: 0;
  left: 0;
  padding: 20px;
`

const InnerWrapperS = styled.div`
  position: relative;
  width: 100%;
  max-height: 80vh;
  overflow: auto;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(42, 47, 42, 0.14);
  background-color: #ffffff;
  padding: 61px 56px;
  color: #2a2f2a;
  margin-top: 45px;
  @media screen and (max-width: 993px) {
    padding: 15px;
    margin-top: 25px;
  }
  button {
    max-width: 212px;
    margin: 52px auto 0;
    display: block;
    @media screen and (max-width: 993px) {
      margin: 15px auto 0;
    }
  }
  h2 {
    font-size: 24px !important;
    margin-bottom: 32px;
    @media screen and (max-width: 993px) {
      margin-bottom: 15px;
    }
  }

  h3 {
    font-size: 16px !important;
    font-weight: bold;
    margin-top: 14px;
    @media screen and (max-width: 993px) {
      font-size: 16px !important;
      margin-top: 10px;
    }
  }
  p {
    margin-top: 14px;
    font-family: Roboto;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #2a2f2a;
    @media screen and (max-width: 993px) {
      font-size: 14px;
    }
    b {
      font-weight: bold;
    }
  }
`

const FooterS = styled.div`
  text-align: center;
  margin-top: 31px;

  a {
    color: ${defaultTheme.colors.pink};
    text-decoration: underline;

    &:hover {
      color: ${defaultTheme.colors.primaryHover};
    }
  }
`
