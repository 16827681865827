import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import cx from 'classnames'
import omit from 'lodash.omit'
import { FormGroup } from 'react-bootstrap'

const InputArea = styled.input`
  height: 55px;
  width: 100%;
  border-radius: 64px;
  border: solid 1px rgba(255, 255, 255, 0.18);
  background-color: transparent;
  outline: none !important;
  padding: 16px 24px 18px;
  caret-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
  ${({ as }) =>
    as === 'textarea'
      ? `
    min-height: 277px;
    border-radius: 24px;
  `
      : ''};
  ${({ hasInfo }) =>
    hasInfo
      ? `
    padding-right: 50px;
  `
      : ''};
  &:not(:disabled) {
    &.Form-Input_filled {
      border-color: ${({ theme }) => theme.colors.greyActive};
    }
    &::placeholder {
      color: #fff;
    }
    &:hover {
      @media screen and (min-width: 768px) {
        border-color: ${({ theme }) => theme.colors.primary};
      }
    }
    &:focus {
      border-color: ${({ theme }) => theme.colors.primary};
      &::placeholder {
        color: ${({ theme }) => theme.colors.grey};
      }
    }
    &.Form-Input_invalid {
      border-color: ${({ theme }) => theme.colors.red};
    }
  }
`

const Input = props => {
  const {
    className,
    id,
    name,
    placeholder,
    type,
    value,
    tag,
    errorMessage,
    info,
  } = props
  return (
    <FormGroup
      className={cx(
        'text-left',
        'position-relative',
        className,
        tag === 'textarea' && 'Form-Input_textarea-wrapper'
      )}
    >
      <label htmlFor={id || name} className="d-none">
        {placeholder}
      </label>
      <InputArea
        {...omit(props, ['className', 'tag'])}
        {...(tag && { tag: type || 'text' })}
        as={!!tag && tag}
        hasInfo={!info}
        className={cx(
          !!value && 'Form-Input_filled',
          errorMessage && 'Form-Input_invalid'
        )}
        id={id || name}
      />
      {info ? <InfoS>{info}</InfoS> : null}
      {errorMessage && (
        <span className="Form-ErrorMessage">{errorMessage}</span>
      )}
    </FormGroup>
  )
}

Input.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  tag: PropTypes.string,
  errorMessage: PropTypes.string,
  info: PropTypes.string,
}

export default styled(Input)`
  width: 100%;
  margin-bottom: 24px;
  @media screen and (min-width: 768px) {
    margin-bottom: 40px;
  }
  .Form-ErrorMessage {
    color: ${({ theme }) => theme.colors.red};
    font-size: 15px;
    letter-spacing: 0.2px;
    padding: 0 24px;
    max-width: 100%;
    word-wrap: break-word;
    display: block;
  }
  &.Form-Input_textarea-wrapper {
    margin-bottom: 18px;
    @media screen and (min-width: 768px) {
      margin-bottom: 34px;
    }
  }
`
export const InfoS = styled.div`
  position: absolute;
  right: 0;
  top: 16px;
  /* transform: translateY(-50%); */
  right: 18px;
  opacity: 0.5;
`
