import validator from 'validator'

const errorMessages = rule => {
  switch (rule) {
    case 'isEmail':
      return 'This is not valid email'
    default:
      return 'This field is required'
  }
}

// rules can be string or array, returns array of errors
const validate = (string, rules) => {
  const check = rule => {
    if (rule === 'isNotEmpty') {
      return !validator.isEmpty(string, { ignore_whitespace: true })
    }
    return validator[rule](string)
  }

  let result = []
  if (`string` === typeof rules) {
    result = [...result, { rule: rules, isValid: check(rules) }]
  }
  if (Array.isArray(rules)) {
    result = [
      ...result,
      ...rules.map(rule => ({
        rule,
        isValid: check(rule),
      })),
    ]
  }

  return result
    .map(({ rule, isValid }) => (!isValid ? errorMessages(rule) : ''))
    .filter(msg => msg !== '')
}

export { validate }
