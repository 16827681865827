import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import cx from 'classnames'

const Burger = styled.span`
  display: inline-block;
  position: relative;
  width: 20px;
  height: 16px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  padding: 0;

  span {
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    transition: all 0.4s ease-in-out, width 0.8s linear;
    transform-origin: 0;
    border-radius: 2px;

    &:first-of-type {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 7px;
    }
    &:nth-of-type(3) {
      top: 14px;
    }
  }

  &.Burger-Close {
    height: 20px;
    opacity: 0.3;
    span {
      width: 29px;
      height: 2.5px;
      &:first-of-type {
        transform: rotate(45deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        top: 20px;
        transform: rotate(-45deg);
      }
    }
  }
`

const BurgerButton = ({ className, isOpen, onClick }) => (
  <button
    type="button"
    onClick={() => onClick()}
    className={cx(className, isOpen && 'BurgerButton-Close')}
    aria-label={`${isOpen ? 'Close' : 'Open'} Mobile Menu`}
  >
    <Burger className={cx(isOpen && 'Burger-Close')}>
      <span />
      <span />
      <span />
    </Burger>
  </button>
)

BurgerButton.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
}

BurgerButton.defaultProps = {
  isOpen: false,
}

export default styled(BurgerButton)`
  border: none;
  background-color: transparent;
  outline: none !important;
  margin: -16px -15px;
  height: calc(100% + 32px);
  padding: 0 15px;

  &:before {
    content: 'Menu';
    display: inline-block;
    margin-right: 12px;
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.24px;
    transition: all 0.4s ease-in-out;
    color: #fff;
  }

  &.BurgerButton-Close {
    width: auto;
    &:before {
      display: none;
      position: absolute;
    }
  }
`
