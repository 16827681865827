import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Logo, Button } from '../common'
import defaultTheme from '../../assets/styles/themes'
import { LayoutContext } from '../Layout'
import { ModalBody } from './ThankYou'

export const InfoModal = () => {
  const { closeModal } = React.useContext(LayoutContext)

  return (
    <ModalBody>
      <h1>Warning !</h1>
      <p>
        Thank you for your help. But there is a bank commission that takes near
        $5 per international transaction. Donate only the amount you can afford.
        Thank you a lot.
      </p>
      <Button onClick={closeModal}>Ok</Button>
    </ModalBody>
  )
}
