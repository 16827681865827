import React, { useContext } from 'react'
import styled from 'styled-components'

import MobileOvod from '../../assets/images/mobile_logo_ovod.svg'
import DskOvod from '../../assets/images/dsk_logo_ovod.svg'
import { LayoutContext } from '../Layout'

const Ovod = props => {
  const { windowWidth } = useContext(LayoutContext)
  return windowWidth < 768 ? <MobileOvod {...props} /> : <DskOvod {...props} />
}

export default styled(Ovod)`
  margin-left: 56px;
  @media screen and (min-width: 768px) {
    margin-left: 39px;
  }
`
