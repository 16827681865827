import React, {
  useEffect,
  useReducer,
  createContext,
  createElement,
} from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import throttle from 'lodash.throttle'
import ReactModal from 'react-modal'
import { graphql, useStaticQuery } from 'gatsby'

import SEO from './SEO'
import defaultTheme from '../assets/styles/themes'
import GlobalStyles from '../assets/styles/global'
import { BlankHeader, BodyWrapper, MainSectionWrapper } from './common'
import Header from './Header'
import Footer from './Footer'
import MobileMenu from './MobileMenu'
import * as AllModals from './modals'

ReactModal.setAppElement('#___gatsby')

const ROOT_QUERY = graphql`
  query ROOT_QUERY {
    homepageBackgrounds: allFile(
      filter: { relativeDirectory: { eq: "homepage-bg" } }
      sort: { order: ASC, fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
          name
          id
        }
      }
    }
    noise: file(name: { eq: "bg_noise" }, ext: { eq: ".png" }) {
      id
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

const initialState = {
  scrollPosition: 0,
  windowWidth: 0,
  isMobileMenuOpen: false,
  modal: null,
  // modal: { component: 'Error' },
}

function reducer(state, action) {
  switch (action.type) {
    case 'SET_WINDOW_SCROLL':
    case 'SET_WINDOW_WIDTH':
    case 'SET_MOBILE_MENU_OPEN':
    case 'OPEN_MODAL':
    case 'CLOSE_MODAL':
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const LayoutContext = createContext(initialState)

const Layout = ({ location, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const data = useStaticQuery(ROOT_QUERY)

  const handleScroll = () =>
    dispatch({
      type: 'SET_WINDOW_SCROLL',
      payload: { scrollPosition: window.pageYOffset },
    })
  const handleResize = () =>
    dispatch({
      type: 'SET_WINDOW_WIDTH',
      payload: { windowWidth: window.innerWidth },
    })
  const toggleMobileMenu = () =>
    dispatch({
      type: 'SET_MOBILE_MENU_OPEN',
      payload: { isMobileMenuOpen: !state.isMobileMenuOpen },
    })
  const handleCloseMobileMenu = () =>
    dispatch({
      type: 'SET_MOBILE_MENU_OPEN',
      payload: { isMobileMenuOpen: false },
    })
  const handleOpenModal = (payload = { component: '', payload: {} }) =>
    dispatch({
      type: 'OPEN_MODAL',
      payload: { modal: payload },
    })
  const handleCloseModal = () =>
    dispatch({
      type: 'CLOSE_MODAL',
      payload: { modal: null },
    })

  useEffect(() => {
    dispatch({
      type: 'SET_WINDOW_WIDTH',
      payload: { windowWidth: window.innerWidth },
    })
    window.addEventListener('scroll', throttle(handleScroll, 410))
    window.addEventListener('resize', throttle(handleResize, 410))
    return () => {
      window.removeEventListener('scroll', throttle(handleScroll, 410))
      window.removeEventListener('resize', throttle(handleResize, 410))
    }
  }, [])

  const Modal = !state.modal?.component
    ? null
    : AllModals[state.modal?.component]

  return (
    <ThemeProvider theme={defaultTheme}>
      {/* default seo */}
      <SEO pathname={location.pathname} />
      {/* inject global css-in-js styles (bootstrap included) */}
      <GlobalStyles />
      {/* Root context */}
      <LayoutContext.Provider
        value={{
          ...state,
          openModal: payload => handleOpenModal(payload),
          closeModal: handleCloseModal,
        }}
      >
        {/* header */}
        <Header
          {...state}
          pathname={location.pathname}
          modifiers={state.scrollPosition > 10 && 'blur'}
          toggleMobileMenu={toggleMobileMenu}
          closeMobileMenu={handleCloseMobileMenu}
        />
        {/* mobile menu */}
        {state.isMobileMenuOpen && (
          <MobileMenu
            pathname={location.pathname}
            isMobileMenuOpen={state.isMobileMenuOpen}
            closeMobileMenu={handleCloseMobileMenu}
          />
        )}
        {/* modal`s */}
        <ReactModal
          isOpen={!!state.modal?.component}
          className="modal-body"
          overlayClassName="modal-overlay"
        >
          {state.modal?.component &&
            createElement(Modal, {
              ...(state.modal?.props && state.modal?.props),
            })}
        </ReactModal>
        {/* body */}
        <BodyWrapper
          className="d-flex flex-column justify-content-between min-vh-100"
          data={data}
        >
          {/* main */}
          <MainSectionWrapper
            location={location}
            windowWidth={state.windowWidth}
            data={data}
          >
            <BlankHeader />
            {children}
          </MainSectionWrapper>
          {/* footer */}
          <Footer
            pathname={location.pathname}
            windowWidth={state.windowWidth}
          />
        </BodyWrapper>
      </LayoutContext.Provider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default Layout
export { LayoutContext }
