import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image-es5'
import omit from 'lodash.omit'

const HomepageMain = styled(BackgroundImage)`
  background-color: transparent;
  background-size: 163px 134px, 122px 464px, 153px 514px, 122px 464px,
    163px 488px, 89px 430px, 471px 672px;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat,
    no-repeat, no-repeat;
  background-position: right top, left 3.2%, right 7.7%, left 17.7%, right 23.4%,
    left 58%, right 77%;
  overflow-x: hidden;
  ${({ helpPage }) =>
    helpPage
      ? `
	    background-position: right top, left 45%, right 100%;
	`
      : null}
`

const MainSectionWrapper = props => {
  const {
    children,
    location: { pathname },
    windowWidth,
    data: {
      homepageBackgrounds: { edges: homepageBackgrounds },
    },
  } = props
  const helpPage = pathname === '/help'

  if ((pathname !== '/' && !helpPage) || windowWidth < 768) {
    return <main>{children}</main>
  }

  let backgrounds = homepageBackgrounds
  if (helpPage) {
    backgrounds = [
      homepageBackgrounds[0],
      homepageBackgrounds[1],
      homepageBackgrounds[2],
    ]
  }
  const mergedHomepageBackgrounds = backgrounds.map(
    ({ node }) => node.childImageSharp?.fluid
  )

  return (
    <HomepageMain
      Tag="main"
      key={mergedHomepageBackgrounds.node?.id}
      fluid={mergedHomepageBackgrounds}
      helpPage={helpPage}
    >
      {children}
    </HomepageMain>
  )
}

MainSectionWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.array,
  ]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  windowWidth: PropTypes.number.isRequired,
  data: PropTypes.shape({
    noise: PropTypes.object.isRequired,
    homepageBackgrounds: PropTypes.array.isRequired,
  }),
}

// const BodyWrapper = props => {
//   const {
//     children,
//     data: { noise },
//   } = props
//   return (
//     <BackgroundImage
//       {...omit(props, ['data'])}
//       key={noise?.id}
//       fluid={noise?.childImageSharp?.fluid}
//     >
//       {children}
//     </BackgroundImage>
//   )
// }

const BodyWrapper = props => {
  const { children } = props
  return <div {...omit(props, ['data'])}>{children}</div>
}

BodyWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.array,
  ]).isRequired,
  data: PropTypes.shape({
    noise: PropTypes.object.isRequired,
    homepageBackgrounds: PropTypes.array.isRequired,
  }),
}

const BW = styled(BodyWrapper)`
  background-repeat: repeat !important;
  opacity: inherit !important;
`

export { BW as BodyWrapper, MainSectionWrapper }
