import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import cx from 'classnames'
import { scroller } from 'react-scroll'

import { Link } from './common'
import { LayoutContext } from './Layout'

const links = [
  { label: 'Why', to: '/#why' },
  { label: 'Icons Revised', to: '/#icons-revised' },
  { label: 'Layouts', to: '/#layouts' },
  { label: 'Modals & Settings Panels', altLabel: 'Modals', to: '/#modals' },
  {
    label: 'Meet Light Aqua Theme',
    altLabel: 'Light UI Theme',
    to: '/#aqua-theme',
  },
  { label: 'Help', to: '/help' },
]

const NavBar = props => {
  const { windowWidth } = useContext(LayoutContext)
  const { className, beforeScrollHandle, pathname } = props
  const onScrollerLinkClick = e => {
    beforeScrollHandle()
    if (pathname !== '/') return null
    if (pathname !== '/help') return null
    e.preventDefault()
    const { hash } = e.target
    if (hash) scroller.scrollTo(hash.substr(1), { smooth: true })
  }
  return (
    <ul className={cx('p-0 m-0', className)}>
      {links.map(({ label, altLabel, to }) => (
        <li key={label} className="list-unstyled">
          <Link
            to={to}
            aria-label={`Scroll to ${label} section`}
            onClick={onScrollerLinkClick}
          >
            {windowWidth > 991 && !!altLabel ? altLabel : label}
          </Link>
        </li>
      ))}
    </ul>
  )
}

NavBar.propTypes = {
  className: PropTypes.string,
  beforeScrollHandle: PropTypes.func,
  pathname: PropTypes.string,
}

NavBar.defaultProps = {
  beforeScrollHandle: () => null,
  pathname: '/',
}

export default styled(NavBar)`
  li {
    &:not(:first-of-type) {
      padding-left: 24px;
    }
    &:not(:last-of-type) {
      padding-right: 24px;
    }
  }
  a {
    width: 100%;
    display: block;

    @media screen and (min-width: 768px) {
      width: auto;
      display: inherit;
    }
  }
`
