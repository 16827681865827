import React, { createElement } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import omit from 'lodash.omit'
import BackgroundImage from 'gatsby-background-image-es5'

const BgOrTagWrapper = props => {
  const { fluid, fixed, Tag } = props
  if (fluid || fixed) {
    return createElement(BackgroundImage, { ...props, Tag: Tag || 'section' })
  }
  return createElement(Tag || 'section', props)
}

BgOrTagWrapper.propTypes = {
  Tag: PropTypes.string,
  fluid: PropTypes.object,
  fixed: PropTypes.object,
}

const Section = props => {
  const { children, beforeContainer, containerProps, afterContainer } = props

  return (
    <BgOrTagWrapper
      {...omit(props, [
        'children',
        'beforeContainer',
        'containerProps',
        'afterContainer',
      ])}
    >
      {beforeContainer}
      <Container {...containerProps}>{children}</Container>
      {afterContainer}
    </BgOrTagWrapper>
  )
}

Section.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.array,
  ]).isRequired,
  beforeContainer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.array,
  ]),
  afterContainer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.array,
  ]),
  containerProps: PropTypes.object,
}

Section.defaultProps = {
  containerProps: {},
}

export default styled(Section)``

export { BgOrTagWrapper }
