const defaultTheme = {
  fonts: {
    primary: "'Roboto', sans-serif",
    secondary: "'Heebo', sans-serif",
  },
  colors: {
    primary: '#d8489d',
    primaryHover: '#fb3dae',
    primaryActive: '#c42684',
    pink: '#ff77ca',
    background: '#0E0F0F',
    grey: 'rgba(255, 255, 255, 0.4)',
    greyHover: 'rgba(255, 255, 255, 0.6)',
    greyActive: 'rgba(255, 255, 255, 0.5)',
    violet: '#8f72ff',
    blue: '#6891ff',
    aqua: '#5eabff',
    red: '#ff3e3e',
  },
}

export default defaultTheme
