import React, { useContext } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'

import { LayoutContext } from '../Layout'
import { Button } from '../common'

const THANK_YOU_MODAL_QUERY = graphql`
  query THANK_YOU_MODAL_QUERY {
    checked: file(name: { eq: "checked" }, ext: { eq: ".svg" }) {
      id
      publicURL
    }
  }
`

const ModalBody = styled.div`
  width: 100%;
  border-radius: 24px;
  box-shadow: 0 21px 114px 0 rgba(0, 0, 0, 0.5);
  background-color: #141517;
  padding: 64px 15px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    margin-top: 0;
    margin-bottom: 0;
  }
  p,
  a {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.2px;
  }
  p {
    color: #8f9090;
    margin-top: 22px;
    margin-bottom: 31px;
  }

  @media screen and (max-width: 576px) {
    h1 {
      font-size: 3.4rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.41;
      letter-spacing: 0.24px;
    }
    p,
    a {
      font-size: 1.5rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.73;
      letter-spacing: 0.2px;
    }
    p {
      margin-top: 16px;
      margin-bottom: 24px;
    }
  }
  @media screen and (min-width: 577px) {
    max-width: 576px;
    padding: 72px 51px;
  }
`
const CheckedIcon = styled.img`
  width: 93px;
  height: 87px;
  display: block;
  margin-bottom: 36px;

  @media screen and (min-width: 577px) {
    width: 124px;
    height: 116px;
    margin-bottom: 51px;
  }
`

const ThankYou = ({ help }) => {
  const { closeModal } = useContext(LayoutContext)
  const { checked } = useStaticQuery(THANK_YOU_MODAL_QUERY)
  let body = (
    <>
      <h1>Yahoo...!</h1>
      <p>
        <span className="Text-White">
          Thank you for getting Tidykit Early Access!
        </span>{' '}
        We are working hard finalizing the first release and will let you know
        with the download link in your email ASAP! Thank you for your patience.
      </p>
    </>
  )
  if (help) body = <h1 style={{ marginBottom: '51px' }}>Thank you!!!</h1>
  return (
    <ModalBody>
      <CheckedIcon key={checked?.id} src={checked?.publicURL} alt="Success" />
      {body}
      <Button onClick={() => closeModal()} modifiers={['fixedSize']}>
        Ok
      </Button>
    </ModalBody>
  )
}

Error.propTypes = {
  help: PropTypes.boolean,
}

export default ThankYou
export { ModalBody }
